<template>
  <section class="my--booking-bookings">
    <SectionLoader :show="loading" />
    <div v-for="booking in bookingOrders" :key="booking.uuid" class="booking--item">
      <card-booking :booking="booking" />
    </div>
  </section>
</template>

<script>
import CardBooking from '@/components/dashboard/my-booking/card-booking';
import { mapState, mapGetters } from 'vuex';

const SectionLoader = () => import('@/components/content-loading/section-loading');

export default {
  components: {
    CardBooking,
    SectionLoader,
  },
  data() {
    return {
      bookings: ['', '', '', ''],
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.v2.booking.actionLoading,
      bookingOrders: (state) => state.v2.booking.bookingOrders,
    }),
    ...mapGetters({
      activeSortBy: 'v2/booking/activeSortBy',
    }),
  },
  methods: {},
  mounted() {},
};
</script>
