<template>
  <section class="my--booking-header">
    <div class="category--container" v-if="$mq !== 'xs'">
      <router-link class="btn btn-primary--outline" to="/dashboard/mylisting">
        {{ $t('dashboard.myListing') }}
      </router-link>
      <router-link class="btn btn-primary" to="/dashboard/mybooking">
        {{ $t('dashboard.myBooking') }}
      </router-link>
      <router-link class="btn btn-primary--outline" to="/dashboard/favorites">
        {{ $t('dashboard.favorite') }}
      </router-link>
    </div>
    <div class="dashboard--search-container">
      <!--      <div class="dropdown&#45;&#45;wrapper">-->
      <!--        <v-select-->
      <!--          :value="activeCategoryObject"-->
      <!--          @input="inputCategoryHandler"-->
      <!--          :hint="-->
      <!--            `${$t('general.filter.category')} ${$t(`general.filter.${activeCategoryObject.name}`)}`-->
      <!--          "-->
      <!--          :items="categories"-->
      <!--          :item-text="categoryLabel"-->
      <!--          item-value="name"-->
      <!--          class="basic&#45;&#45;dropdown"-->
      <!--          persistent-hint-->
      <!--          return-object-->
      <!--          single-line-->
      <!--        />-->
      <!--      </div>-->
      <div class="dropdown--wrapper">
        <v-select
          :value="activeSortBy"
          @input="inputSortByHandler"
          :hint="`${$t('general.filter.sort')} ${$t(`general.filter.${activeSortBy.name}`)}`"
          :items="sortBys"
          :item-text="sortByLabel"
          item-value="name"
          class="basic--dropdown"
          persistent-hint
          return-object
          single-line
        />
      </div>
      <!--      <div class="dropdown&#45;&#45;wrapper">-->
      <!--        <v-select-->
      <!--          :value="listingType"-->
      <!--          @input="inputListingTypeHandler"-->
      <!--          :items="listingTypes"-->
      <!--          :hint="`${$t('addListing.listingType')} ${$t(`general.filter.${listingType}`)}`"-->
      <!--          class="basic&#45;&#45;dropdown"-->
      <!--          persistent-hint-->
      <!--          single-line-->
      <!--        >-->
      <!--          <template slot="selection" slot-scope="{ item }">-->
      <!--            {{ $t(`general.filter.${item}`) }}-->
      <!--          </template>-->
      <!--          <template slot="item" slot-scope="{ item }">-->
      <!--            {{ $t(`general.filter.${item}`) }}-->
      <!--          </template>-->
      <!--        </v-select>-->
      <!--      </div>-->
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'booking-header',
  computed: {
    ...mapState({
      categories: (state) => state.v2.booking.categoryNavList,
      sortBys: (state) => state.v2.booking.sortByNavList,
      listingType: (state) => state.v2.booking.listingType,
      listingTypes: (state) => state.global.listingTypes,
    }),
    ...mapGetters({
      activeSortBy: 'v2/booking/activeSortBy',
      activeCategoryObject: 'v2/booking/activeCategoryObject',
    }),
  },
  methods: {
    inputSortByHandler(value) {
      this.$store.dispatch('v2/booking/changeActiveSortBy', value.name);
    },
    inputCategoryHandler(value) {
      let query = Object.assign({}, this.$route.query);
      delete query.page;
      this.$router.replace({ query });
      this.$store.dispatch('v2/booking/changeActiveCategory', value.name);
    },
    inputListingTypeHandler(value) {
      this.$store.commit('v2/booking/SET_LISTING_TYPE', value);
      this.$store.dispatch('v2/booking/fetchBookingOrders');
      this.$store.dispatch('v2/booking/getTotalBookingOrders');
    },
    sortByLabel(value) {
      return this.$i18n.t(`general.filter.${value.name}`);
    },
    categoryLabel(value) {
      return this.$i18n.t(`general.filter.${value.name}`);
    },
  },
};
</script>
