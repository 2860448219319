<template>
  <router-link
    :to="`/mybooking/detail?type=${booking.type}&uuid=${booking.uuid}`"
    class="card--booking"
    style="text-decoration: none"
  >
    <div class="left--part">
      <a :href="booking.listing.web_url">
        <picture>
          <source :srcset="booking.listing.primary_photo" type="image/webp" />
          <source :srcset="getFallbackImage(booking.listing.primary_photo)" type="image/jpeg" />
          <img :src="getFallbackImage(booking.listing.primary_photo)" />
        </picture>
      </a>
    </div>
    <div class="right--part">
      <div class="top--part">
        <div class="title--text">
          {{ booking.listing.listing_title }}
        </div>
      </div>
      <div class="bottom--part">
        <div class="left--col">
          <div class="label--container">
            <div class="label">
              {{ $t(`general.filter.${booking.listing.type}`) }}
            </div>
            <div class="label">{{ booking.listing.property_type_name }}</div>
          </div>
          <!--            <div class="location">-->
          <!--              <img src="@/assets/img/icons/pin-1.svg" alt="Location Pin" />-->
          <!--              <span>Jakarta Selatan</span>-->
          <!--            </div>-->
        </div>
        <div class="right--col">
          <div class="item">
            <div class="label--container">
              <div class="label">
                {{ booking.status.message }}
              </div>
            </div>
          </div>
          <div class="item" v-show="tourDate">
            {{ tourDate }}
          </div>
          <!--          <div class="item" v-show="rentPeriod">-->
          <!--            {{ rentPeriod }}-->
          <!--          </div>-->
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';

export default {
  mixins: [HelperMixin],
  components: {},
  props: {
    booking: {
      require: true,
    },
  },
  computed: {
    ...mapState({
      listingType: (state) => state.v2.booking.listingType,
    }),
    tourDate() {
      if (!this.booking.tour_date) return null;
      return `${this.$t('general.tourPeriod')} ${this.$date.format(
        this.booking.tour_date,
        'dd MMMM yyyy | HH:mm',
      )}`;
    },
    rentPeriod() {
      if (!this.booking.rent_period) return null;
      const difference =
        this.$date.parse(this.booking.rent_period.start_date) -
        this.$date.parse(this.booking.rent_period.end_date);
      const differenceInDays = Math.round(Math.abs(difference / (24 * 60 * 60 * 1000)));
      if (differenceInDays > 0) {
        return `${this.$t('general.rentPeriod')} ${this.$date.format(
          this.booking.rent_period.start_date,
          'dd MMMM yyyy',
        )} - ${this.$date.format(this.booking.rent_period.end_date, 'dd MMMM yyyy')}`;
      } else {
        return `${this.$t('general.rentPeriod')}  ${this.$date.format(
          this.booking.rent_period.start_date,
          'dd MMMM yyyy',
        )} | ${this.$date.format(
          this.booking.rent_period.start_date,
          'HH:mm',
        )} - ${this.$date.format(this.booking.rent_period.end_date, 'HH:mm')}`;
      }
    },
  },
};
</script>
